<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Editar Questão">
      <form-question :enunciated="enunciated" action="edit"></form-question>
    </base-card>
  </v-container>
</template>
<script>

export default {
  data: () => ({
    enunciated: { text: '' },
    page: {
      title: 'Questões'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Questões',
        disabled: false,
        to: '/question/list'
      },
      {
        text: 'Editar Questões',
        disabled: false,
        to: '/question/edit'
      }
    ]
  })

}
</script>
